<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-menu
            class="el-menu-demo"
            mode="horizontal"
            :ellipsis="false"
            @select="headerMenuClick"
        >
          <el-menu-item index="0">
            <img src="@/assets/logo.png" class="cherry-logo" alt="logo">
          </el-menu-item>
          <div class="flex-grow" />
          <el-sub-menu index="user">
            <template #title>
              {{ user.name }}
            </template>
            <el-menu-item index="logout">退出登录</el-menu-item>
          </el-sub-menu>
          <el-menu-item index="menu" class="cherry-mobile-menu">
            <el-icon :size="18" color="#000000">
              <Menu />
            </el-icon>
          </el-menu-item>
        </el-menu>
      </el-header>
      <el-container>
        <el-aside class="cherry-aside" width="200">
          <el-menu
              :router="true"
              :default-active="defaultRouter"
          >
            <div v-for="(item, key) in sidebar.data" :key="key">
              <el-sub-menu :index="item.index" v-if="item.children && item.children.length > 0">
                <template #title>{{ item.name }}</template>
                <el-menu-item v-for="(val,index) in item.children" :key="index" :index="val.index" v-text="val.name"></el-menu-item>
              </el-sub-menu>

              <el-menu-item :index="item.index" v-else v-text="item.name"></el-menu-item>
            </div>
          </el-menu>
        </el-aside>
        <el-main class="cherry-main">
          <router-view />
        </el-main>
      </el-container>
    </el-container>

    <!-- 移动端菜单-->
    <el-drawer
        v-model="sidebar.show"
        title="导航"
        :size="200"
    >
      <el-menu
          :router="true"
          :default-active="defaultRouter"
      >
        <div v-for="(item, key) in sidebar.data" :key="key">
          <el-sub-menu :index="item.index" v-if="item.children && item.children.length > 0">
            <template #title>{{ item.name }}</template>
            <el-menu-item v-for="(val,index) in item.children" :key="index" :index="val.index" v-text="val.name"></el-menu-item>
          </el-sub-menu>

          <el-menu-item :index="item.index" v-else v-text="item.name"></el-menu-item>
        </div>
      </el-menu>
    </el-drawer>
    <!-- end 移动端菜单-->
  </div>
</template>

<script>
import {getUser} from '@/plugins/auth'
export default {
  name: 'Main',
  data() {
    return {
      defaultRouter: '/',
      user: {},
      sidebar: {
        show: false,
        data: [
          {index: '/', name: '首页'},
          {index: 'member', name: '用户管理', children: [
              {
                index: '/member/index',
                name: '用户管理',
              },
              {
                index: '/member/group',
                name: '家庭管理',
              },
            ]},
          {
            index: 'pet', name: '宠物管理', children: [
              {
                index: '/pet/index',
                name: '宠物管理',
              },
              {
                index: '/pet/breed',
                name: '品种管理',
              },
            ]
          },
          {
            index: 'goods', name: '商品管理', children: [
              {
                index: '/goods/index',
                name: '商品列表',
              },
              {
                index: '/goods/category',
                name: '商品分类',
              },
            ]
          },
          {
            index: 'goods', name: '订单管理', children: [
              {
                index: '/order/index',
                name: '订单列表',
              },
            ]
          }
        ]
      },
    }
  },
  created() {
    this.routerActive()
    this.user = getUser()
  },
  methods: {
    routerActive () {
      this.defaultRouter = this.$route.meta && this.$route.meta.active ?
          this.$route.meta.active :
          this.$route.path
    },
    headerMenuClick(index) {
      if (index === 'logout') {
        this.$messageBox.confirm(
            '确定要退出登录吗？',
            '退出',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
            }
        ).then(() => {
          this.$router.push({name: 'logout'})
        })
      }

      if (index === 'change') {
        this.change.show = true
      }

      if (index === 'menu') {
        this.sidebar.show = true
      }
    }
  },
  watch: {
    $route(to, from) {
      console.log(to, from)
      this.sidebar.show = false
    }
  }
}
</script>

<style scoped>
.el-header {
  padding: 0;
  height: 60px;
  line-height:58px;
}
.el-menu {
  border-right: none;
}
.cherry-logo {
  height: 40px;
  padding-top:9px;
  padding-bottom: 8px;
  padding-right: 10px;
}
.cherry-aside {
  height:100%;
  position: fixed;
  top:60px;
  width:200px;
  border-right: solid 1px #dcdfe6;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.cherry-main {
  position: fixed;
  height: calc(100% - 60px);
  width:calc(100% - 200px);
  top:60px;
  left:201px;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    left: 0;
  }
}
.cherry-mobile-menu {
  display: none;
  padding-left:0;
  padding-right: 0;
  text-align: center;
  width: 50px;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.flex-grow {
  flex-grow: 1;
}
.el-sub-menu.is-active .el-sub-menu__title, .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}
</style>
